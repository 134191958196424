<template>
  <div class="text-center">
    <MessageBox
      v-model="dialogShow"
      title="Статистика рассылки"
      scrollable
      max-width="90%"
    >
      <div v-if="statusLoading" class="text-center">
        <v-progress-circular
          class="text-center"
          :size="150"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>

      <div v-if="!statusLoading && !showPlug">
        <v-row class="mt-1 d-flex justify-space-between">
          <v-col class="text-center">
            <h1 style="color: #4776e6">{{ this.statistic.send_count }}</h1>
            <p style="color: #4776e6">Отправлено</p>
          </v-col>
          <v-col class="text-center">
            <h1 style="color: #00d15d">{{ this.statistic.read_count }}</h1>
            <p style="color: #00d15d">Прочитано</p>
          </v-col>
          <v-col class="text-center">
            <h1 style="color: #b5b5c4">
              {{ this.statistic.send_count - this.statistic.read_count }}
            </h1>
            <p style="color: #b5b5c4">Не прочитано</p>
          </v-col>
        </v-row>
        <chart
          type="doughnut"
          :data="dataChart"
          :options="optionsChart"
          :height="chartHeight"
        />
      </div>

      <base-empty-block-page
        v-if="showPlug"
        title="Здесь пока ничего нет :’("
        action-icon="$iconify_plus-circle-outlined"
        action-text="Создать отправку"
        action
        @action="
          $router.push({
            name: 'Sendings',
            params: { id: idItem },
            hash: '#dialog',
          })
        "
      >
        <template v-slot:image>
          <v-img
            src="@/assets/png/settings-dummy.png"
            width="186px"
            height="156px"
          />
        </template>
      </base-empty-block-page>
    </MessageBox>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Chart from "@/views/widgets/components/Chart";
import textCenterPlugin from "@/views/widgets/components/graphs/plugins/textCenter";
import MessageBox from "@/components/message-box/MessageBox.vue";
import ChartMain from "chart.js";

export default {
  components: { Chart, MessageBox },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idItem: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      chartHeight: 450,
      statusLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getStatistics: "company/notifications/getStatisticsNotifications",
    }),
  },
  computed: {
    ...mapGetters({
      statistic: "company/notifications/statistic",
    }),
    dialogShow: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
        return;
      },
    },
    optionsChart() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "right",
          labels: {
            fontSize: 30,
          },
        },
        elements: {
          center: {
            text: this.statistic.send_count,
            color: "#4776E6", // Default is #000000
            fontStyle: "Arial", // Default is Arial
            sidePadding: 20, // Default is 20 (as a percentage)
            minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
            lineHeight: 25, // Default is 25 (in px), used for when text wraps
          },
        },
      };
    },
    dataChart() {
      return {
        datasets: [
          {
            data: [
              this.statistic.read_count,
              this.statistic.send_count - this.statistic.read_count,
            ],
            backgroundColor: ["#00D15D", "#B5B5C4"],
          },
        ],

        labels: [
          `Прочитанно ${this.statistic.read_count} (${Math.round(
            (this.statistic.read_count / this.statistic.send_count) * 100
          )}%)`,
          `Не прочитанно ${
            this.statistic.send_count - this.statistic.read_count
          } (${Math.round(
            ((this.statistic.send_count - this.statistic.read_count) /
              this.statistic.send_count) *
              100
          )}%)`,
        ],
      };
    },
    showPlug() {
      return !Object.keys(this.statistic).length && !this.statusLoading
        ? true
        : false;
    },
  },
  watch: {
    async dialogShow(value) {
      if (value) {
        this.statusLoading = true;
        try {
          await this.getStatistics(this.idItem);
        } catch (error) {
          console.log(error);
        } finally {
          this.statusLoading = false;
        }
      }
    },
  },
  mounted() {
    ChartMain.pluginService.register({
      beforeDraw: function (chart) {
        textCenterPlugin(chart);
      },
    });
  },
};
</script>
<template>
  <div class="pls--page-header">
    <div class="pls--page-header-title title-m-bold">
      Рассылка
    </div>
    <v-spacer />
    <base-text-field
      v-model="search"
      class="pls--search field-search"
      placeholder="Поиск рассылки"
      prepend-inner-icon="$iconify_ion-search-outline"
      clear-icon="$iconify_ion-close-circle-outline"
      :prepend-inner-icon-color="this.$vuetify.theme.themes.light['neutral-500']"
      clearable
      hide-details
    />
    <v-btn
      class="pls--page-header-btn-right"
      color="primary"
      @click="$router.push({name: 'MailingMaster'})"
    >
      <v-icon left>
        $iconify_plus-circle-outlined
      </v-icon>Создать рассылку
    </v-btn>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        search: '',
      }
    },
    watch: {
      search (v) {
        this.$emit('update:search', v)
      },
    },
    methods: {
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/pls/page/_page.scss";

.pls--search {
  max-width: 449px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pls--page-table"},[_c('base-table',{staticClass:"table-template",attrs:{"headers":_vm.tableHeaders,"items":_vm.templates,"search":_vm.search,"total-count":_vm.templates.length,"word-operations":['рассылка', 'рассылки', 'рассылок'],"sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc},on:{"update:sortBy":function($event){_vm.tableSortBy=$event},"update:sort-by":function($event){_vm.tableSortBy=$event},"update:sortDesc":function($event){_vm.tableSortDesc=$event},"update:sort-desc":function($event){_vm.tableSortDesc=$event},"click:row":function($event){return _vm.routeTemplateEdit($event.id)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.channel_type",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(_vm.langChannelType[item.channel_type])+" ")])]}},{key:"item.moderation_status",fn:function(ref){
var item = ref.item;
return [(item.moderation_status === 'TEMPLATE' && item.on_moderation === false )?_c('v-btn',{attrs:{"loading":item.action,"small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.sendToModeration(item.id)}}},[_vm._v(" Отправить на модерацию ")]):_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(_vm.langModerationStatus[item.moderation_status === 'TEMPLATE' && item.on_moderation === false ? 'WAIT': item.moderation_status])+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"item__switch-active",staticStyle:{"margin-left":"100%"},attrs:{"loading":item.loadingChangeActive,"disabled":item.loadingChangeActive,"inset":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.changeActive(item.id, $event)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.action}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.getItemActions(item)),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                'body-s-medium' : true,
                'neutral-500--text':true,
              }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)}),_c('Statistic',{attrs:{"idItem":_vm.statisticId},model:{value:(_vm.statisticShow),callback:function ($$v) {_vm.statisticShow=$$v},expression:"statisticShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="pls--page-table">
    <base-table
      class="table-template"
      :headers="tableHeaders"
      :items="templates"
      :search="search"
      :total-count="templates.length"
      :word-operations="['рассылка', 'рассылки', 'рассылок']"
      :sort-by.sync="tableSortBy"
      :sort-desc.sync="tableSortDesc"
      @click:row="routeTemplateEdit($event.id)"
    >
      <template v-slot:[`item.id`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ item.id }}
        </p>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ item.name }}
        </p>
      </template>
      <template v-slot:[`item.channel_type`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ langChannelType[item.channel_type] }}
        </p>
      </template>
      <template v-slot:[`item.moderation_status`]="{ item }">
        <v-btn
          v-if="item.moderation_status === 'TEMPLATE' && item.on_moderation === false "
          :loading="item.action"
          small
          color="secondary"
          @click.stop="sendToModeration(item.id)"
        >
          Отправить на модерацию
        </v-btn>
        <p
          v-else
          class="body-s-medium mb-0"
        >
          {{ langModerationStatus[item.moderation_status === 'TEMPLATE' && item.on_moderation === false ? 'WAIT': item.moderation_status] }}
        </p>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ item.description }}
        </p>
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          v-model="item.active"
          class="item__switch-active"
          style="margin-left: 100%"
          :loading="item.loadingChangeActive"
          :disabled="item.loadingChangeActive"
          inset
          hide-details
          @click.stop=""
          @change="changeActive(item.id, $event)"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              :loading="item.action"
              v-on="on"
            >
              <v-icon>$iconify_feather-more-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(menuItem, index) in getItemActions(item)"
              :key="index"
              @click="menuItem.action(item)"
            >
              <v-list-item-icon>
                <v-icon color="neutral-500">
                  {{ menuItem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class=" {
                  'body-s-medium' : true,
                  'neutral-500--text':true,
                }"
              >
                {{ menuItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </base-table>
    <Statistic 
    v-model="statisticShow"
    :idItem="statisticId"  
    />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import Statistic from './Statistic.vue'

  export default {
    components: {
      Statistic
    },
    props: {
      search: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        tableHeaders: [
          { text: 'ID', align: 'start', value: 'id' },
          { text: 'Название', align: 'start', value: 'name' },
          { text: 'Тип рассылки', align: 'start', value: 'channel_type' },
          { text: 'Статус модерации', align: 'start', value: 'moderation_status' },
          { text: 'Описание', align: 'start', value: 'description' },
          { text: '', align: 'end', value: 'active' },
          { text: 'Действия', align: 'end', value: 'actions' },
        ],
        langChannelType: {
          PUSH_DATABASE: 'PUSH-уведомление',
          SMS: 'SMS-сообщение',
          EMAIL: 'EMAIL-сообщение',
          APPLE_WALLET: 'Apple Wallet',
          WHATS_APP: 'WhatsApp',
          EDA: 'Приложение eDA',
        },
        langModerationStatus: {
          WAIT: 'Ожидает отправки',
          TEMPLATE: 'На модерации',
          REJECT: 'Отклонено',
          ACCEPT: 'Принята',
        },
        tableKey: 'MailingTable',
        tableOptions: {},
        tableSortBy: 'id',
        tableSortDesc: true,
        loadingDelete: {},
        statisticShow: false,
        statisticId: 0
      }
    },
    computed: {
      programId () {
        return this.$store.getters.programId
      },
      templates () {
        return this.$store.getters['company/notifications/templates']
      },
    },
    watch: {

    },
    methods: {
      ...mapActions({
        deleteTemplate: 'company/notifications/delete',
        createTemplate: 'company/notifications/create',
        sendToModeration: 'company/notifications/sendToModeration',
        sendNotification: 'company/notifications/sendNotification',
      }),
      getItemActions (item) {
        return [
          {
            icon: '$iconify_feather-trending-up',
            title: 'Статистика',
            action: () => {
              this.openModalStatistic(item.id)
            },
            show: item.moderation_status === 'ACCEPT',
          },
          {
            icon: '$iconify_feather-play',
            title: 'Отправить себе',
            action: this.onSendTemplateToYourself.bind(null, item),
            show: true,
          },
          {
            icon: '$iconify_feather-send',
            title: 'Отправить',
            action: () => {
              this.$router.push({ name: 'Sendings', params: { id: item.id }, hash: '#dialog' })
            },
            show: item.moderation_status === 'ACCEPT' && item.active,
          },
          {
            icon: '$iconify_feather-edit',
            title: 'Редактировать',
            action: this.routeTemplateEdit.bind(null, item.id),
            show: true,
          },
          {
            icon: '$iconify_feather-plus',
            title: 'Создать как копия',
            action: this.onCreateCopyTemplate.bind(null, item),
            show: true,
          },
          {
            icon: '$iconify_feather-send',
            title: 'Отправки',
            action: () => {
              this.$router.push({ name: 'Sendings', params: { id: item.id } })
            },
            show: item.moderation_status === 'ACCEPT',
          },
          {
            icon: '$iconify_feather-send',
            title: 'На модерацию',
            action: () => {
              this.sendToModeration(item.id)
            },
            show: item.moderation_status === 'TEMPLATE',
          },
          {
            icon: '$iconify_feather-trash',
            title: 'Удалить',
            action: this.onDeleteTemplate.bind(null, item),
            show: true,
          },
        ].filter(x => x.show)
      },
      openModalStatistic (id) {
        this.statisticId = id
        this.statisticShow = true
      },
      async onDeleteTemplate (item) {
        try {
          this.loadingDelete[item.id] = true
          await this.deleteTemplate(item)
        } finally {
          this.loadingDelete[item.id] = false
        }
      },
      async onCreateCopyTemplate (item) {
        this.$router.push({
          name: 'MailingMasterById',
          params: {
            id: item.id,
          },
        })
      },
      async onSendTemplateToYourself (item) {
        await this.sendNotification(item.id)
      },
      async changeActive (id, active) {
        await this.$store.dispatch('company/notifications/changeActive', { id: id, active })
      },
      routeTemplateEdit (id) {
        this.$router.push({
          name: 'MailingEdit',
          params: {
            id: id,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/pls/page/_page.scss";

.pls--page-table {
  margin-top: 10px;
}

.table-template {
  & ::v-deep {
    tbody {
        tr {
          cursor: pointer;
      }
    }
  }
}
</style>
